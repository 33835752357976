import React from "react";
import styled from "styled-components";

import { TRANSITION_SLOW } from "uiKit/theme/transitions";
import { portraitTablet } from "uiKit/theme/mediaQueries";

import { AltCTALink } from "marketing/components/CTALink";

export default function PricingSection() {
    return (
        <Root>
            <ContentWrapper>
                <h2>
                    <HeadingText>Pay for what you need.</HeadingText>
                    <HeadingText>No hidden costs. No upgrade fees.</HeadingText>
                </h2>
                <p>
                    Whether you&apos;re a single engineer or a team of 100, we
                    offer flexible pricing options that are guaranteed to scale
                    with your business. Need a few extra users this month? No
                    worries, only pay for what you need!
                </p>
                <p>
                    We also offer discounts for yearly subscriptions and
                    multiple users. Better yet, there are no hidden costs and no
                    upgrade fees, so you only have a single number to worry
                    about with no surprises!
                </p>
            </ContentWrapper>
            <InfoBoxWrapper>
                <InfoBox>
                    <h4>
                        <b>Simple and affordable pricing</b>
                    </h4>
                    <p>
                        Flexible monthly and yearly plans available on a per
                        user or calculation based basis.
                    </p>
                    <AltCTALink href="/pricing">
                        Explore our full pricing options
                    </AltCTALink>
                </InfoBox>
                <InfoBox>
                    <h4>
                        <b>Free tools</b>
                    </h4>
                    <p>
                        Experience ClearCalcs through our free tools, including
                        section properties and free FEM beam and column
                        calculations.
                    </p>
                    <AltCTALink href="/freetools">
                        Use our range of free tools
                    </AltCTALink>
                </InfoBox>
            </InfoBoxWrapper>
        </Root>
    );
}

const Root = styled.div`
    margin: 0 auto;
    padding: 100px 20px 0px;
    max-width: 1200px;
    display: flex;
    align-items: start;

    text-align: left;
    overflow: hidden;

    ${portraitTablet`
        flex-direction: column;
        padding-top: 20px;
    `};

    > img {
        padding-right: 50px;

        ${portraitTablet`
            padding-bottom: 30px;
            padding-right: 0;
            max-width: 300px;
            margin: 0 auto;
        `};
    }
`;

const ContentWrapper = styled.div`
    flex: 1 1 60%;
    p {
        margin-top: 1em;
        transition-property: opacity, transform;
        transition-duration: ${TRANSITION_SLOW};
        opacity: 1;

        &:nth-child(3) {
            transition-delay: 0.2s;
        }
        &:nth-child(4) {
            transition-delay: 0.4s;
        }
    }
`;

const InfoBoxWrapper = styled.div`
    flex: 1 1 40%;
    padding-left: 50px;

    ${portraitTablet`
        padding-left: 0px;
        margin-top: 50px;
    `};
`;
const InfoBox = styled.div`
    &:first-child {
        margin-bottom: 50px;
    }
`;

const HeadingText = styled.span`
    display: inline-block;
    white-space: nowrap;

    ${portraitTablet`
        white-space: initial;
    `};
`;
