import React from "react";
import styled from "styled-components";
import Image from "next/legacy/image";

import { AltCTALink, HeroCTALink } from "marketing/components/CTALink";
import { blue600, blue400 } from "ui/colors";
import screenshot from "../images/appScreen.jpg";
import calcsScreenshot from "../images/calcsScreen.jpg";
import { mobile, homepageMenuTablet } from "uiKit/theme/mediaQueries";

export default function FeatureSecton() {
    return (
        <Root>
            <FirstContent>
                <h2>Everything you need to engineer quickly and accurately.</h2>
                <p>
                    Powerful, easy to use software with a library of thousands
                    of common structural sections and a broad range of everyday
                    structural calculations.
                </p>
                <div>
                    <HeroCTALink href="/signup">
                        Start your free trial
                    </HeroCTALink>
                </div>
                <ScreenshotWrapper>
                    <div>
                        <Screenshot>
                            <Image src={screenshot} alt="" />
                        </Screenshot>
                        <AltCTALink href="/calculations">
                            Explore our calculations library
                        </AltCTALink>
                    </div>

                    <div>
                        {" "}
                        <Screenshot>
                            <Image src={calcsScreenshot} alt="" />
                        </Screenshot>
                        <AltCTALink href="/features">
                            Learn why engineers choose ClearCalcs
                        </AltCTALink>
                    </div>
                </ScreenshotWrapper>
            </FirstContent>
        </Root>
    );
}

const Root = styled.div`
    position: relative;

    &:before {
        background: linear-gradient(-15deg, ${blue400}, ${blue600} 80%);
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 70%;
    }
`;

const ScreenshotWrapper = styled.div`
    min-height: 400px;
    max-width: 1200px;
    width: 80%;
    margin: 0 auto 50px;
    position: relative;

    display: flex;
    justify-content: space-between;

    ${homepageMenuTablet`
        flex-direction: column;

        > :first-child > :last-child > a{
            color:white;
        }
    `} ${mobile`
        margin-top: 0;
    `};
`;

const Screenshot = styled.div`
    margin: 20px;
    img {
        width: 100%;
        box-shadow: 0 4px 40px 0px rgba(0, 0, 0, 0.3);
    }
`;
const FirstContent = styled.div`
    margin: 0 auto;
    padding: 100px 20px 0px;
    max-width: 1400px;

    text-align: center;

    color: white;

    > * {
        z-index: 1;
        position: relative;
    }

    p {
        margin-bottom: 20px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
    }

    > div {
        margin-bottom: 20px;
    }
`;
