import React from "react";
import styled, { css } from "styled-components";
import { desktop, portraitTablet } from "uiKit/theme/mediaQueries";
import { TRANSITION_SLOW } from "uiKit/theme/transitions";
import Image from "next/legacy/image";
import type { StaticImageData } from "next/legacy/image";

import QuoteSVG from "./QuoteSVG";
import { grey200, grey100 } from "ui/colors";

type Props = {
    children?: React.ReactNode;
    animate: boolean;
    quote: string;
    userName: string;
    userTitle: string;
    companyName: string;
    userPhotoSrc?: string;
    customerImg?: StaticImageData;
    customerImgAlt?: string;
    suppressBackground?: boolean;
};
export default React.forwardRef(function TestimonalBox(
    props: Props,
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <Root suppressBackground={props.suppressBackground} ref={ref}>
            <ContentWrapper>
                <QuoteMark
                    animate={props.animate}
                    lighten={props.suppressBackground}
                />
                <Quote animate={props.animate}>
                    <UserQuote cite={props.userName}>
                        <span>{props.quote}</span>
                    </UserQuote>
                    {props.children}
                </Quote>
                <UserDetailsWrapper animate={props.animate}>
                    {props.userPhotoSrc && (
                        <img
                            src={props.userPhotoSrc}
                            alt={`${props.userName}`}
                        />
                    )}
                    {props.customerImg && (
                        <ImageContainer>
                            <Image
                                src={props.customerImg}
                                alt={props.userName}
                                layout="fixed"
                                width="150"
                                height="100"
                                objectFit="cover"
                                objectPosition="top"
                            />
                        </ImageContainer>
                    )}

                    <div>
                        <p>
                            <b>{props.userName}</b>
                        </p>
                        <p>
                            <em>{props.userTitle}</em>
                        </p>
                        <p>
                            <em>{props.companyName}</em>
                        </p>
                    </div>
                </UserDetailsWrapper>
            </ContentWrapper>
        </Root>
    );
});
const ImageContainer = styled.div`
    width: 150px;
    margin-bottom: 20px;
    margin-left: 20px;

    & > span {
        min-height: 150px;
        border-radius: 100%;
        border: 1px solid ${grey200};
    }

    ${desktop`
        margin-left: 0;
    `};
`;
const Root = styled.div<{ suppressBackground?: boolean }>`
    background: ${(props) => (props.suppressBackground ? "inherit" : grey100)};
    padding: 50px 20px;
    position: relative;
    margin-top: 50px;
`;
const ContentWrapper = styled.div`
    display: flex;
    align-items: center;

    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    ${portraitTablet`
        flex-direction: column-reverse;
    `};
`;

const Quote = styled.div<{ animate: boolean }>`
    padding-left: 100px;
    padding-right: 30px;
    flex: 2 2 100%;

    margin-bottom: 30px;
    position: relative;
    z-index: 1;

    > * {
        transition-property: transform, opacity;
        transition-duration: ${TRANSITION_SLOW};
        opacity: ${(props) => (props.animate ? 0 : 1)};
        transform: ${(props) =>
            props.animate ? "translateY(-100px)" : "translateY(0)"};

        &:first-child {
            transition-delay: 0.2s;
        }
    }

    ${portraitTablet`
        padding-left: 0px;
        padding-right: 0;
    `};
`;
const QuoteMark = styled(QuoteSVG)<{ animate: boolean; lighten?: boolean }>`
    width: 200px;
    position: absolute;
    top: -100px;
    left: 0px;
    color: ${(props) => (props.lighten ? grey100 : grey200)};
    opacity: 0.5;

    transition-property: transform, opacity;
    transition-duration: ${TRANSITION_SLOW};
    transition-delay: 0.3s;

    opacity: ${(props) => (props.animate ? 0 : 1)};
    transform: ${(props) =>
        props.animate ? "translateY(100px)" : "translateY(0)"};
`;

const UserQuote = styled.blockquote`
    font-size: 1.5em;
`;

const UserDetailsWrapper = styled.div<{ animate: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    align-items: center;
    position: relative;
    z-index: 1;
    > img {
        margin-bottom: 20px;
        border-radius: 100%;
        border: 1px solid ${grey200};
        max-width: 150px;
    }

    ${portraitTablet`
        flex-direction: row-reverse;
        justify-content: center;
        text-align: left;

        > img {
            margin-left: 20px;
        }
    `};

    > img,
    p {
        transition-property: transform, opacity;
        transition-duration: ${TRANSITION_SLOW};

        opacity: ${(props) => (props.animate! ? 0 : 1)};
        transform: ${(props) =>
            props.animate! ? "translateY(-100px)" : "translateY(0)"};
    }
    > img {
        transition-delay: 0.5s;
    }
    p {
        &:nth-child(1) {
            transition-delay: 0.4s;
        }
        &:nth-child(2) {
            transition-delay: 0.3s;
        }
        &:nth-child(3) {
            transition-delay: 0.2s;
        }
    }
`;
