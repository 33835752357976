import React from "react";
import styled from "styled-components";

import { grey500 } from "ui/colors";

export default function BenefitBox(props) {
    return (
        <Root className={props.className}>
            <img src={props.icon} alt="" />
            <h4>{props.title}</h4>
            <p>{props.children}</p>
        </Root>
    );
}

const Root = styled.div`
    padding: 10px;
    > img {
        height: 50px;
    }

    > h4 {
        color: ${grey500};
        margin-bottom: 10px;
    }
`;
