import React from "react";
import Image from "next/legacy/image";
import styled, { css } from "styled-components";

import { TRANSITION_SLOW } from "uiKit/theme/transitions";
import { portraitTablet } from "uiKit/theme/mediaQueries";

import { AltCTALink } from "marketing/components/CTALink";

import { ReactComponent as CalcsImage } from "../images/calcs.svg";

type Props = {
    animate: boolean;
};
export default React.forwardRef(function CalculationsSection(
    props: Props,
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <Root ref={ref}>
            <ImageWrapper>
                <CalcsImage />
            </ImageWrapper>
            <ContentWrapper animate={props.animate}>
                <h2>
                    <HeadingText>Standards based calculations.</HeadingText>
                    <HeadingText>And plenty of them.</HeadingText>
                </h2>
                <p>
                    Covering standards across Australia and New Zealand, Europe
                    and North America, our network of talented engineers are
                    always working to add the calculations you need to our
                    ever-growing list.
                </p>
                <p>
                    Whether it&apos;s a simple timber beam or steel connection,
                    a more complex retaining wall or portal frame, or anything
                    in &apos;re looking for.
                </p>
                <p>
                    Have some custom designs stuck in spreadsheets? We can even
                    help you convert them to leverage the power of ClearCalcs
                    and keep everything in one place.
                </p>
                <AltCTALink href="/calculations">
                    See our full library of calculations
                </AltCTALink>
            </ContentWrapper>
        </Root>
    );
});

const ImageWrapper = styled.div`
    padding-right: 50px;
    display: block;
    flex: 1 1 100%;
    max-width: 40%;
    position: relative;

    ${portraitTablet`
        padding-bottom: 30px;
        padding-right: 0;
        max-width: 300px;
        margin: 0 auto;
    `};
`;
const Root = styled.div`
    margin: 0 auto;
    padding: 100px 20px;
    max-width: 1200px;
    display: flex;
    align-items: center;

    text-align: left;
    overflow: hidden;

    ${portraitTablet`
        flex-direction: column;
        padding-top: 20px;
    `};
`;

const ContentWrapper = styled.div<Props>`
    p {
        margin-top: 1em;
        transition-property: opacity, transform;
        transition-duration: ${TRANSITION_SLOW};
        opacity: 1;

        &:nth-child(3) {
            transition-delay: 0.2s;
        }
        &:nth-child(4) {
            transition-delay: 0.4s;
        }
    }

    ${(props) =>
        props.animate
            ? css`
                  p {
                      transform: translateX(100px);
                      opacity: 0;
                  }
              `
            : ""};
`;

const HeadingText = styled.span`
    display: inline-block;
    white-space: nowrap;

    ${portraitTablet`
        white-space: initial;
    `};
`;
