import React from "react";
import styled, { css } from "styled-components";

import Benefit from "./BenefitBox";
import { AltCTALink } from "marketing/components/CTALink";
import { TRANSITION_SLOW } from "uiKit/theme/transitions";
import { portraitTablet } from "uiKit/theme/mediaQueries";

import resultsSrc from "../images/benefits/results.svg";
import understandSrc from "../images/benefits/understand.svg";
import neverloseSrc from "../images/benefits/neverlose.svg";
import helpSrc from "../images/benefits/help.svg";
import saveTimeSrc from "../images/benefits/savetime.svg";
import accessSrc from "../images/benefits/access.svg";
import collaborationSrc from "../images/benefits/collaboration.svg";
import upgradeSrc from "../images/benefits/upgrade.svg";
import mobileSrc from "../images/benefits/mobile.svg";

type Props = {
    animate: boolean;
};
export default React.forwardRef(function BenefitsSection(
    props: Props,
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <Root ref={ref}>
            <h2>Eliminate engineering compromise</h2>
            <p>
                Reduce overconservatism with FEA design, eliminate wait time for
                licenses, and allow collaboration anywhere
            </p>
            <BenefitsGrid animate={props.animate}>
                <Benefit icon={resultsSrc} title="More accurate results.">
                    Get far better quality and efficiency than spreadsheets with
                    highly accurate FEA calculations and dynamic load path
                    tracking between members.
                </Benefit>
                <Benefit icon={saveTimeSrc} title="Eliminate wasted time.">
                    Eliminate time wasted using clunky and inaccurate methods or
                    waiting for software licenses to free up​.
                </Benefit>
                <Benefit icon={neverloseSrc} title="Available everywhere.">
                    Engineer effectively from office, home, or site, with all
                    work automatically saved to our secure cloud servers.
                </Benefit>
                <Benefit icon={understandSrc} title="Easy to understand.">
                    Impress clients and checkers with professional, transparent,
                    and easy to understand calculations and quick export to PDF.
                </Benefit>
                <Benefit icon={accessSrc} title="Always have access">
                    Our simple pricing model makes it easy to give everyone
                    access to ClearCalcs when they need it, no hassle.
                </Benefit>
                <Benefit icon={helpSrc} title="Help when you need it.">
                    Need help? Our customer support is built right into our
                    platform. With a single click you can talk to one of our
                    talented engineers.
                </Benefit>
                <Benefit icon={collaborationSrc} title="Easy collaboration.">
                    No more clunky and argumentative shared drives or sending
                    files over email! Everything is shared inside ClearCalcs so
                    you can easily collaborate on projects.
                </Benefit>
                <Benefit icon={upgradeSrc} title="Upgrades are always free.">
                    We add new features and calculation modules every single
                    week based on discussions with users and what will help them
                    work better. Updates are available the moment they&apos;re
                    released at no extra cost, and with no install required.
                </Benefit>
                <Benefit icon={mobileSrc} title="Mobile. Tablet. Desktop.">
                    ClearCalcs was designed to work on any modern device.
                    Nothing to download or install, all you need is a web
                    browser.
                </Benefit>
            </BenefitsGrid>
            <AltCTALink href="/features">Explore our other benefits</AltCTALink>
        </Root>
    );
});

const Root = styled.div`
    margin: 0 auto;
    padding: 100px 20px;
    max-width: 1400px;

    text-align: center;
    overflow: hidden;
`;

const BenefitsGrid = styled.div<Props>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px auto 0;

    > * {
        flex: 1 1 30%;
        padding: 20px;
        transition-property: opacity, transform;
        transition-duration: ${TRANSITION_SLOW};
        opacity: 1;
    }
    > :nth-child(1) {
        transition-delay: 0s;
    }
    > :nth-child(6) {
        transition-delay: 0.2s;
    }
    > :nth-child(8) {
        transition-delay: 0.4s;
    }
    > :nth-child(3) {
        transition-delay: 0.6s;
    }
    > :nth-child(7) {
        transition-delay: 0.8s;
    }
    > :nth-child(4) {
        transition-delay: 1s;
    }
    > :nth-child(2) {
        transition-delay: 1.2s;
    }
    > :nth-child(9) {
        transition-delay: 1.4s;
    }
    > :nth-child(5) {
        transition-delay: 1.6s;
    }

    ${(props) =>
        props.animate
            ? css`
                  > * {
                      opacity: 0;
                  }
                  > :nth-child(1) {
                      transform: translate(-70px, -70px);
                  }
                  > :nth-child(6) {
                      transform: translateX(100px);
                  }
                  > :nth-child(8) {
                      transform: translateY(100px);
                  }
                  > :nth-child(3) {
                      transform: translate(70px, -70px);
                  }
                  > :nth-child(7) {
                      transform: translate(-70px, 70px);
                  }
                  > :nth-child(4) {
                      transform: translateX(-100px);
                  }
                  > :nth-child(2) {
                      transform: translateY(-100px);
                  }
                  > :nth-child(9) {
                      transform: translate(70px, 70px);
                  }
                  > :nth-child(5) {
                      transform: scale(0.7);
                  }
              `
            : ""};

    ${portraitTablet`
            > :nth-child(7), > :nth-child(5), > :nth-child(6) {
                display: none;
            }

            > * {
                flex: 1 1 250px;
            }
        `};
`;
