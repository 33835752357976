import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { mobile } from "uiKit/theme/mediaQueries";

import Header from "header/Header";
import TrustedBySection from "./components/TrustedBySection";
import FeaturesSection from "./components/FeaturesSection";
import TestimonialBox from "./components/TestimonialBox";
import CalculationsSection from "./components/CalculationsSection";
import BenefitsSection from "./components/BenefitsSection";
import PricingSection from "./components/PricingSection";
import SignUpSection from "marketing/components/SignUpSection";
import Footer from "footer/Footer";
import userImageHarrySrc from "./images/userImageHarry.jpg";
import userImageMurraySrc from "./images/userImageMurray.jpg";
import HeroSection from "./components/HeroSection";

export default function Homepage() {
    const [animateCalculations, setAnimateCalculations] = useState(true);
    const [animateFirstTestimonial, setAnimateFirstTestimonial] =
        useState(true);
    const [animateBenefits, setAnimateBenefits] = useState(true);
    const [animateSecondTestimonial, setAnimateSecondTestimonial] =
        useState(true);

    const calculationsRef = useRef<HTMLDivElement | null>(null);
    const firstTestimonialRef = useRef<HTMLDivElement | null>(null);
    const benefitsRef = useRef<HTMLDivElement | null>(null);
    const secondTestimonialRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let ticking = false;
        function onScroll() {
            const animationThreshhold = (window.innerHeight / 3) * 2;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    if (calculationsRef.current) {
                        const calculationsBBox =
                            calculationsRef.current.getBoundingClientRect();
                        setAnimateCalculations(
                            animateCalculations &&
                                calculationsBBox.top > animationThreshhold,
                        );
                    }

                    if (firstTestimonialRef.current) {
                        const firstTestimonialBBox =
                            firstTestimonialRef.current.getBoundingClientRect();
                        setAnimateFirstTestimonial(
                            animateFirstTestimonial &&
                                firstTestimonialBBox.top > animationThreshhold,
                        );
                    }

                    if (benefitsRef.current) {
                        const benefitsBBox =
                            benefitsRef.current.getBoundingClientRect();
                        setAnimateBenefits(
                            animateBenefits &&
                                benefitsBBox.top > animationThreshhold,
                        );
                    }

                    if (secondTestimonialRef.current) {
                        const secondTestimonialBBox =
                            secondTestimonialRef.current.getBoundingClientRect();
                        setAnimateSecondTestimonial(
                            animateSecondTestimonial &&
                                secondTestimonialBBox.top > animationThreshhold,
                        );
                    }
                    ticking = false;
                });
                ticking = true;
            }
        }
        window.addEventListener("scroll", onScroll);
        onScroll();

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [
        animateCalculations,
        animateFirstTestimonial,
        animateBenefits,
        animateSecondTestimonial,
    ]);

    return (
        <Root>
            <Header />
            <HeroSection />
            <TrustedBySection />
            <FeaturesSection />
            <CalculationsSection
                animate={animateCalculations}
                ref={calculationsRef}
            />
            <TestimonialBox
                quote="We sometimes had to use two packages for accurate member loading and section capacity, ClearCalcs brings this together by allowing us to specify exact support conditions. Overall, the flexibility afforded by features within ClearCalcs are far superior to what we had to endure under our previous software packages. And to top it off, their team is great!"
                userPhotoSrc={userImageHarrySrc.src}
                userName="Harry K."
                userTitle="National Volume Housing Manager"
                companyName="Intrax Consulting Engineers"
                animate={animateFirstTestimonial}
                ref={firstTestimonialRef}
            ></TestimonialBox>
            <BenefitsSection animate={animateBenefits} ref={benefitsRef} />
            <TestimonialBox
                quote="Faster, more accurate design, easier to modify calculations, just all around better than a spreadsheet and hand calcs and significantly cheaper and more reliable than any other calculations software I have used."
                userPhotoSrc={userImageMurraySrc.src}
                userName="Murray P."
                userTitle="Senior Engineer"
                companyName="Vision Engineers"
                animate={animateSecondTestimonial}
                ref={secondTestimonialRef}
            />
            <PricingSection />
            <SignUpSection boxed />
            <Footer />
        </Root>
    );
}

const Root = styled.article`
    background: white;

    ${mobile`
        h2 {
            font-size: 1.5em;
        }
    `};
`;
