import SEO from "framework/components/SEO";
import Homepage from "homepage/Homepage";

export default function Page() {
    return (
        <>
            <SEO
                title={false} // Manage Homepage Title Specially
                description="Online structural design software. Calculation tools for shear force, bending moment, cantilever deflection, steel beam calculations, wood, concrete and more."
                canonicalHref={"/"}
            />
            <Homepage />
        </>
    );
}
