import React from "react";
import styled from "styled-components";
import track from "track";

import {
    FeatureCard,
    Heading,
    Link,
    Paragraph,
    SubHeading,
    PrimaryButtonLink,
    Text,
    SecondaryButtonLink,
} from "ui";
import { spacing100, spacing50, spacing75, spacing125 } from "ui/spacing";
import { IconArrowRight, IconCalculator, IconHammer } from "ui/icons";
import { grey100 } from "ui/colors";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { mobile, portraitTablet } from "uiKit/theme/mediaQueries";

import HeroSectionImgSrc from "../images/heroSection.svg";
import {
    DEFAULT_HERO_HEADER_TITLE_TEXT,
    DEFAULT_HERO_HEADER_SUBTITLE_TEXT,
    DEFAULT_BUILD_CARD_LINK_TEXT,
    DEFAULT_DESIGN_CARD_LINK_TEXT,
    DEFAULT_SIGNUP_BUTTON_TEXT,
    DEFAULT_CONTACT_US_BUTTON_TEXT,
} from "../ExperimentConstants";

export default function HeroSection() {
    const ldClient = useLDClient();

    const heroHeaderTitle =
        ldClient?.allFlags()["perm-86cuztvu4"] ||
        DEFAULT_HERO_HEADER_TITLE_TEXT;
    const heroHeaderSubtitle =
        ldClient?.allFlags()["perm-86cuztvvb"] ||
        DEFAULT_HERO_HEADER_SUBTITLE_TEXT;
    const buildCardLink =
        ldClient?.allFlags()["perm-86cuztww7"] || DEFAULT_BUILD_CARD_LINK_TEXT;
    const designCardLink =
        ldClient?.allFlags()["perm-86cuztwxt"] || DEFAULT_DESIGN_CARD_LINK_TEXT;
    const signupButton =
        ldClient?.allFlags()["perm-86cuztwz3"] || DEFAULT_SIGNUP_BUTTON_TEXT;
    const contactUsText =
        ldClient?.allFlags()["perm-86cuztwyz"] ||
        DEFAULT_CONTACT_US_BUTTON_TEXT;

    return (
        <Root>
            <HeroContainer>
                <div>
                    <Heading bold allowNewLine>
                        {heroHeaderTitle}
                    </Heading>
                    <Paragraph>{heroHeaderSubtitle}</Paragraph>
                    <ControlsWrapper>
                        <PrimaryButtonLink
                            href="/signup"
                            onClick={() => {
                                track("Clicked CTA", {
                                    Context: "Homepage",
                                    Content: [
                                        heroHeaderTitle,
                                        heroHeaderSubtitle,
                                        signupButton,
                                    ],
                                });
                            }}
                        >
                            <Text>{signupButton}</Text>
                        </PrimaryButtonLink>
                        <SecondaryButtonLink
                            href="/contact"
                            onClick={() => {
                                track("Clicked CTA", {
                                    Context: "Homepage",
                                    Content: [
                                        heroHeaderTitle,
                                        heroHeaderSubtitle,
                                        contactUsText,
                                    ],
                                });
                            }}
                        >
                            <Text>{contactUsText}</Text>
                        </SecondaryButtonLink>
                    </ControlsWrapper>
                </div>
                <div>
                    <HeroImage src={HeroSectionImgSrc} />
                </div>
            </HeroContainer>
            <CardList>
                <FeatureCard>
                    <IconBox>
                        <IconCalculator size={24} />
                    </IconBox>
                    <SubHeading bold>
                        Design with our extensive calculator library
                    </SubHeading>
                    <Paragraph>
                        Ready-to-use structural calculations for wood, steel,
                        cold-formed steel, and concrete built to US, AU, EU, CA,
                        and NZ building codes.
                    </Paragraph>
                    <Paragraph>
                        <Link
                            href="/calculations"
                            onClick={() => {
                                track("Clicked CTA", {
                                    Context: "Homepage",
                                    Content: designCardLink,
                                });
                            }}
                        >
                            <Text>{designCardLink}</Text>&nbsp;
                            <IconArrowRight />
                        </Link>
                    </Paragraph>
                </FeatureCard>
                <FeatureCard>
                    <IconBox>
                        <IconHammer size={24} />
                    </IconBox>
                    <SubHeading bold>
                        Build your own structural calculators
                    </SubHeading>
                    <Paragraph>
                        Create your own structural design calculators with our
                        easy-to-use platform, or let our team build custom
                        solutions for you.
                    </Paragraph>
                    <Paragraph>
                        <Link
                            href="/persona/manufacturers"
                            onClick={() => {
                                track("Clicked CTA", {
                                    Context: "Homepage",
                                    Content: buildCardLink,
                                });
                            }}
                        >
                            <Text>{buildCardLink}</Text>&nbsp;
                            <IconArrowRight />
                        </Link>
                    </Paragraph>
                </FeatureCard>
            </CardList>
        </Root>
    );
}

// TODO - Refactor this out once we update a few more
// of these home page sections.
const Root = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    margin: 50px auto 50px;
`;

const HeroContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing100};

    > :first-child {
        max-width: 400px;
    }

    ${portraitTablet`
        flex-direction: column-reverse;

        > :first-child {
            max-width: initial;
        }
    `}

    ${mobile`
        flex-direction: column-reverse;

        > :last-child {
            width: 100%;
        }
    `}
`;

const HeroImage = styled.img`
    width: 100%;
`;

const ControlsWrapper = styled.div`
    display: flex;
    gap: ${spacing75};
    margin-top: ${spacing125};

    ${mobile`
        flex-direction: column;
        flex-wrap: wrap;

        > * {
            flex: 1;
        }
    `}
`;

const CardList = styled.div`
    display: flex;
    gap: ${spacing100};

    > * {
        flex: 1;
    }

    ${mobile`
        flex-direction: column;
    `}
`;

const IconBox = styled.div`
    height: 2.5em;
    width: 2.5em;
    padding: ${spacing50};

    border-radius: 4px;
    background-color: ${grey100};
`;
