import React from "react";
import styled from "styled-components";

const SVGRoot = styled.svg`
    fill: currentColor;
    path: currentColor;
`;
type Props = {
    className?: string;
};

const QuoteSVG = ({ className }: Props) => (
    <SVGRoot className={className} viewBox="0 0 160 140">
        <path
            d="M72.9,0l-1.2,10.5c-15.3,6.1-26.5,13-33.6,20.8C30.9,39,26.8,47.9,25.6,58c-0.7,6-0.3,10.2,1.1,12.6c1.2,2.6,2.9,4,5,4.2
    c2.1,0.3,5.1,0,8.8-0.9c3.7-0.8,7.1-1.1,10-0.7c6.7,0.8,12.3,4.1,16.7,9.9c4.4,5.8,6.2,12.4,5.4,19.9c-0.9,8.2-4.7,14.8-11.4,20
    c-6.7,5.1-14.6,7.2-23.5,6.1c-11-1.3-20.3-7.5-28-18.4C1.9,99.8-1.2,87,0.4,72.6c1.9-17,9.1-32.3,21.6-45.7
    C34.5,13.5,51.5,4.5,72.9,0z M155.8,10l-0.7,9.7c-17.3,8.5-28.9,16.5-34.8,24c-5.9,7.4-9.2,16.4-10,27c-0.4,4.8,0.3,8.5,1.9,11
    c1.6,2.5,3.4,3.9,5.4,4.1c1.8,0.1,4.6-0.3,8.3-1.4c3.7-1.1,7.4-1.5,11-1.2c6.7,0.6,12.4,3.6,17,9c4.6,5.5,6.6,11.9,6.1,19.2
    c-0.6,8.4-4.4,15.4-11.1,21c-6.8,5.6-14.8,8.1-24,7.3c-10.8-0.9-20.2-6.6-28.2-17c-8-10.5-11.4-23-10.3-37.5
    c1.4-18,8.1-33.9,20.2-47.7C118.7,23.7,135.1,14.5,155.8,10z"
        />
    </SVGRoot>
);

export default QuoteSVG;
