export const DEFAULT_HERO_HEADER_TITLE_TEXT =
    "Structural Engineering Calculations.\nMade Easy.";

export const DEFAULT_HERO_HEADER_SUBTITLE_TEXT =
    "Get your structural calculations done in a single platform. Let ClearCalcs optimize your workflow so you can accomplish more with less work.";

export const DEFAULT_BUILD_CARD_LINK_TEXT = "Find out more";

export const DEFAULT_DESIGN_CARD_LINK_TEXT = "Explore calculations";

export const DEFAULT_SIGNUP_BUTTON_TEXT = "Get started";

export const DEFAULT_CONTACT_US_BUTTON_TEXT = "Contact us";
