import React from "react";
import styled from "styled-components";
import { blue400, blue500 } from "ui/colors";

import { Link } from "ui";
import { TRANSITION_SLOW } from "uiKit/theme/transitions";

type LinkProps = {
    href: string;
    className?: string;
    children: React.ReactNode;
    noMargin?: boolean;
    target?: "_blank" | "_self";
    inheritColor?: boolean;
    onClick?: () => void;
};
type LinkOrAProps = {
    href: string;
    className?: string;
    children: React.ReactNode;
    noMargin?: boolean;
    target?: "_blank" | "_self";
    onClick?: () => void;
};

const CTALinkComponent = (props: LinkProps) => (
    <Root noMargin={!!props.noMargin}>
        <CTALink
            {...props}
            href={props.href}
            className={props.className}
            target={props.target}
            inheritColor={props.inheritColor}
        >
            <span>{props.children}</span>
        </CTALink>
    </Root>
);

export default CTALinkComponent;

export const AltCTALink = (props: LinkOrAProps) => (
    <Root noMargin={!!props.noMargin}>
        <BlueCTALink
            {...props}
            href={props.href}
            className={props.className}
            target={props.target}
        >
            <span>{props.children}</span>
        </BlueCTALink>
    </Root>
);

export const FilledCTALink = (props: LinkProps) => {
    return (
        <Root noMargin={!!props.noMargin}>
            <FilledBlueCTALink
                {...props}
                href={props.href}
                className={props.className}
                target={props.target}
            >
                <span>{props.children}</span>
            </FilledBlueCTALink>
        </Root>
    );
};

const Root = styled.div<{ noMargin?: boolean }>`
    line-height: 1.5;
    text-align: center;
    margin-top: ${(props) => (!!props.noMargin ? "0" : "2em")};
`;
const CTALink = styled(Link)`
    line-height: 1.5;
    display: inline-block;
    padding: 0.75em;
    border: 1px solid;
    border-radius: 3px;
`;

const BlueCTALink = styled(CTALink)`
    color: ${blue400};
`;

const FilledBlueCTALink = styled(CTALink)`
    background: ${blue400};
    color: white;
    border-color: ${blue500};
    transition: background ${TRANSITION_SLOW};

    &:hover {
        background: ${blue500};

        > span {
            border-color: transparent;
        }
    }
`;

export const HeroCTALink = styled(CTALink)`
    background: #f0d62c;
    color: black;
    margin-right: 10px;
    line-height: 1.5em;
`;
