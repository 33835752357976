import React from "react";
import styled, { css } from "styled-components";

import { grey300 } from "ui/colors";

import { FilledCTALink } from "./CTALink";

type Props = {
    title?: string;
    subText?: string;
    boxed?: boolean;
    noBorder?: boolean;
    templateCode?: string | null;
};

const SignUpSection = ({
    title,
    subText,
    boxed = false,
    noBorder = false,
    templateCode,
}: Props) => {
    const querystring = new URLSearchParams();
    if (templateCode) {
        querystring.set("templateCode", templateCode);
    }
    const signupUrl = templateCode
        ? `/signup?${querystring.toString()}`
        : "/signup";

    return (
        <Root>
            <ContentWrapper boxed={boxed}>
                <h2>{title || "Sign up today. No credit card required."}</h2>
                <p>
                    {subText ||
                        `Experience the full power of ClearCalcs with a 14
                day free trial and start being more productive.`}
                </p>
                <FilledCTALink href={signupUrl}>
                    Start your free trial
                </FilledCTALink>
            </ContentWrapper>
        </Root>
    );
};

export default SignUpSection;
const Root = styled.div`
    margin: 50px 20px;
`;
const ContentWrapper = styled.div<{ boxed: boolean }>`
    max-width: 1200px;

    padding: 50px 20px;
    position: relative;
    text-align: center;

    margin: 0 auto;

    h2 {
        margin-bottom: 1em;
    }

    ${(props) =>
        props.boxed
            ? css`
                  background: white;
                  border: 1px solid ${grey300};
                  border-radius: 5px;
                  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
              `
            : ""}
`;
